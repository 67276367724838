function Singlespacecomp({showsingleComponent}){
    return (
        <section className="bigSection">
        <section className="singleSect">
            <div className="divsingleSect">
                {showsingleComponent}
            </div>        
        </section>
    
        </section>
        
    )
    }
    
    export default Singlespacecomp;